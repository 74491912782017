<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.wormingForm.openForm()"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add New Worming Record</span>
              </v-tooltip>
            </v-card-title>
            <v-divider></v-divider>

            <v-data-table
              :headers="wormingHeaders"
              :items="dog.wormings"
              no-data-text="No Worming Records"
              :items-per-page="-1"
            >
              <template v-slot:item.date="{ item }">
                {{ formatDateTime(item.date, false) }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.wormingForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>

                <v-btn
                  bottom
                  x-small
                  depressed
                  color="red lighten-4 red--text"
                  @click="openDelete(item, 'Worming')"
                >
                  <v-icon x-small>mdi-archive</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <WormingForm ref="wormingForm" />
      <v-dialog
        scrollable
        v-model="deleteWorming.dialog"
        persistent
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline"
            >Archive {{ deleteWorming.item }}</v-card-title
          >
          <v-card-text>Are you sure you wish to archive?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" depressed @click="resetDelete"
              >No, cancel</v-btn
            >
            <v-btn
              color="success"
              depressed
              :loading="deleteWorming.loading"
              @click="saveDelete()"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import WormingForm from "../components/WormingForm";
export default {
  components: {
    WormingForm,
  },

  data() {
    return {
      deleteWorming: {
        dialog: false,
        item: null,
        model: {},
        loading: false,
      },
      wormingHeaders: [
        { text: "Date", value: "date" },
        { text: "Worming Product", value: "product.name" },
        { text: "Weight", value: "weight" },
        { text: "Dose", value: "product.dose_rate_unit" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },
  },

  methods: {
    openDelete(model, item) {
      this.deleteWorming.model = model;
      this.deleteWorming.dialog = true;
      this.deleteWorming.item = item;
    },

    resetDelete() {
      this.deleteWorming.dialog = false;
      this.deleteWorming.model = {};
      this.deleteWorming.loading = false;
      this.deleteWorming.item = null;
    },

    saveDelete() {
      this.deleteWorming.loading = true;

      let playloadUrl = null;
      let args = [];

      playloadUrl = "lhl/dogs/deleteWorming";
      args = {
        appId: this.$route.params.id,
        dogId: this.$route.params.dogId,
        wormingId: this.deleteWorming.model.id,
      };

      this.$store
        .dispatch(playloadUrl, args)
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteWorming.loading = false;
        });
    },
  },
};
</script>
